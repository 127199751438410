<template>
    <section>
        <main>
            <header>
                <h4>Clients</h4>
            </header>
            <template v-if="!isMobile()">
                <article>
                    <template v-if="loaded && clientsList.length">
                        <template v-for="(client, i) in clientsList" :key="i">
                            <div class="client" @click="saveClient(client.name, client.id, client.logo, client.user_client_role.role_details)">
                                <figure>
                                    <img v-if="client.logo" :src="imgURL + client.logo" alt="">
                                    <span v-else class="no-logo">
                                        {{client.name.charAt(0)}}
                                    </span>
                                </figure>
                                <span class="name">{{client.name}}</span>
                            </div>
                        </template>
                    </template>
                    <template v-else-if="loaded && !clientsList.length">
                        <div class="no-client">
                            <span>You are not assigned to any client.</span>
                            <span class="logout" @click="handleLogout">logout</span>
                        </div>
                    </template>
                    <template v-else-if="!loaded">
                        <div class="loading">
                            <span class="loader"></span>
                        </div>
                    </template>
                </article>
            </template>
            <template v-else>
                <MobileSelect :data="clientsList" :type="'client'" @sendOrg="handleFromMobile" />
            </template>
        </main>
    </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { LOGOUT_USER } from "@/store/storeconstants";
import axiosInstance from '@/services/AxiosTokenInstance';
import isMobile from '@/mixins/isMobile.js'
import MobileSelect from './MobileSelect.vue'
export default {

    props: {
        primaryColor: {
            type: String
        }
    },

    mixins: [isMobile],

    components: {
        MobileSelect
    },

    data() {
        return {
            clientsList: [],
            imgURL: process.env.VUE_APP_DO_SPACES,
            loaded: false,
        }
    },
    methods: {
        ...mapActions("auth", {
            logout: LOGOUT_USER,
        }),
        ...mapMutations({
            setRole: "account/setClientRole",
        }),

        async getClients() {
            this.loaded = false;
            const org = JSON.parse(localStorage.getItem("organization"));
            const response = await axiosInstance.get(
                `/new-org-client-list-all?org_id=${org.id}&user_client=1`
            );
            if (response) {
                this.loaded = true;
                this.clientsList = response.data.data;
            } else {
                this.loaded = true;
                this.emitter.emit("alert", "Something went wrong");
            }
        },

        async saveClient(name, id, logo, role) {
            const currentUser = JSON.parse(localStorage.getItem("U_P"));
            currentUser.role = role;
            localStorage.setItem("U_P", JSON.stringify(currentUser));
            let clientData = {
                id: id,
                name: name,
                logo: logo,
            };
            localStorage.setItem("client", JSON.stringify(clientData));
            this.setRole(role);
            this.$router.push("/data-structure");
        },

        handleLogout() {
            this.logout();
            this.emitter.emit("logout");
            this.$router.replace("/");
            this.$emit('close')
        },

        handleFromMobile(data) {
            this.saveClient(data.name, data.id, data.logo, data.user_client_role.role_details)
        }
    },

    mounted() {
        this.getClients()
    }
}
</script>


<style lang="scss" scoped>
section {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    gap: 0.5rem;
    position: absolute;
    main {
        background: white;
        width: 100%;
        max-width: 500px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 1px 3px $grey;
        display: flex;
        flex-direction: column;
        position: relative;
        transition: 0.2s;

        @media only screen and (max-width: 1200px) {
            box-shadow: unset;
            background: none;
        }
        
        header {
            padding: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
            box-shadow: 0px 1px 3px $grey;
            
            @media only screen and (max-width: 1200px) {
                display: none;
            }

            h4 {
                font-size: 1rem;
                text-transform: uppercase;
                margin: 0;
                transition: 0.2s;
                color: var(--primary-color);
            }
        }
        article {
            padding: 20px;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            gap: 1.25rem;

            .no-client, .client {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
            }

            .no-client {
                width: 100%;
                .logout {
                    cursor: pointer;
                    user-select: none;
                    background: $red;
                    padding: 0.5rem 1rem;
                    color: white;
                    text-transform: capitalize;
                    transition: 0.2s;

                    &:hover {
                        background: $red-hover;
                    }
                }
            }

            .client {
                cursor: pointer;
                user-select: none;
                figure {
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .no-logo {
                        width: 100%;
                        height: 100%;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;
                        font-size: 1.5rem;
                        background: var(--primary-color);
                    }
                }

                .name {
                    font-weight: 500;
                }
            }
        }
    }
}

.loading {
    padding: 5px 0;
    background: none;
    height: unset;

    .loader {
        border: 3px solid #f3f3f3;
        border-top: 3px solid var(--primary-color);
        width: 20px;
        height: 20px;
    }
}
</style>