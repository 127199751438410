<template>
    <main>
        <figure>
            <img :src="`/assets/images/home/${activeOrg}.webp`" alt="company_display">
        </figure>
        <div class="bg-color" v-if="isMobile()"></div>
        <section class="data">
            <figure class="bg">
                <img src="@/assets/img/home/elements.webp" alt="additional_elements">
            </figure>
            <LangsButton />
            <Content />
            <Footer />
        </section>
    </main>
</template>

<script>
import LangsButton from '@/components/Ui/Home/LangsButton.vue'
import Footer from '@/components/Ui/Home/Footer.vue'
import Content from '@/components/Ui/Home/Content.vue'
import isMobile from '@/mixins/isMobile.js'
export default {
    components: {
        LangsButton,
        Footer,
        Content
    },

    mixins: [isMobile],

    computed: {
        activeOrg() {
            console.log(window.location.hostname)
            if(window.location.hostname === 'serviceapp.cheto.eu') return 'cheto'
            else if(window.location.hostname === 'remoteassistance.altice-empresas.pt') return 'altice'
            return 'immera'
        }
    },

    methods: {
        loadVariables() {
            let cssLink = document.createElement('link')
            cssLink.rel = 'stylesheet';
            if(this.activeOrg === 'cheto') {
                console.log('here')
                cssLink.href = '/assets/css/_variables-cheto.css'
                document.head.appendChild(cssLink)
            }
            else if(this.activeOrg === 'altice') {
                cssLink.href = '/assets/css/_variables-altice.css'
                document.head.appendChild(cssLink)
            }
        }
    },

    created() {
        this.loadVariables()
    }
}
</script>

<style lang="scss" scoped>
main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
    }

    .data, figure {
        height: 100%;
    }

    figure {
        width: calc(100% - 720px);
        display: flex;
        justify-content: flex-end;
        transition: 0.2s;
        @media only screen and (max-width: 1200px) {
            width: 100%;
            height: 40%;
            position: absolute;
            top: 0;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .bg-color {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        background: var(--primary-color);
    }

    .data {
        width: 720px;
        padding: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        @media only screen and (max-width: 1200px) {
            width: 95%;
            background: white;
            position: absolute;
            bottom: 0;
            height: 80vh;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }

        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

</style>