<template>
    <section>
        <div v-if="forgotpw" class="return" @click="forgotpw = false">
            <figure :class="{opened: isOpened}">
              <img src="/assets/images/left-arrow.svg" alt="chevron">
            </figure>
            <span>back</span>
        </div>
        <main>
            <header>
                <transition name="slide-left">
                    <h4 v-if="!forgotpw">Login</h4>
                    <template v-else>
                        <h4>Forgot Password</h4>
                    </template>
                </transition>
            </header>
            <form @submit.prevent="handleBtn" @keyup.enter="handleBtn">
                <div class="item">
                    <label>Email</label>
                    <input :class="{error: !isEmail && email.length}" type="email" v-model="email" placeholder="Email" @click="isEmail = true" v-click-outside="emailValidation" required :disabled="loading">
                </div>
                <template v-if="!forgotpw">
                    <div class="item">
                        <label>Password</label>
                        <input type="password" v-model="password" placeholder="Password" required :disabled="loading">
                    </div>
                    <div class="forgot">
                        <span @click="forgotpw = true">Forgot your password?</span>
                    </div>
                </template>
                
                <button type="submit" class="btn" :style="{background: primaryColor}">
                    <span v-if="!loading && !forgotpw">Login</span>
                    <span v-else-if="!loading && forgotpw">Send Email</span>
                    <div v-else class="loading">
                        <span class="loader"></span>
                    </div>
                </button>
            </form>
        </main>
    <transition name="move">
        <alert v-if="alerts.length" :alerts="alerts" />
    </transition>
    </section>
</template>

<script>
import { mapActions } from 'vuex'
import { AUTH_ACTION } from '@/store/storeconstants'
import axiosInstance from '@/services/AxiosTokenInstance';
import Alert from "@/components/Ui/General/Alert.vue";
import validateEmail from '@/mixins/validation'
export default {
    props: {
        primaryColor: {
            type: String
        }
    },
    data() {
        return {
            email: '',
            password: '',
            loading: false,
            alerts: [],
            isEmail: true,
            forgotpw: false
        }
    },
    components: {
        Alert
    },
    mixins: [validateEmail],
    watch: {
        alerts: {
            deep: true,
            handler() {
                setTimeout(() => {
                    this.alerts.splice(this.alerts.length - 1, 1);
                }, 3000);
            },
        },
    },
    methods: {
        ...mapActions('auth',{
            login: AUTH_ACTION,
        }),

        async onLogin() {
            this.loading = true

            const response = await axiosInstance.post('login', {email: this.email, password: this.password})
            .catch((error) => {
                this.alerts.push(error.response.data.message);
            })
            .finally(() => this.loading = false);

            if(response) {
                this.login(response)
                this.$emit('success')
            }
        },

        async handleForgot() {
            this.loading = true
            const response = await axiosInstance.post(`forgot-password`, {email: this.email}).finally(() => this.loading = false);
            console.log(response)
            if (response.status === 200) {
                this.alerts.push('Please check your email.')
                this.forgotpw = false
            }
            else this.alerts.push(response.data.message)
        },

        handleBtn() {
            if (!this.forgotpw) this.onLogin()
            else this.handleForgot()
        },

        emailValidation() {
            this.isEmail = this.validateEmail(this.email) ? true : false
        }
    }
}
</script>


<style lang="scss" scoped>
.move-enter-active {
  animation: move 0.2s;
}
.move-leave-active {
  animation: move 0.2s reverse;
}
.slide-left-enter-active {
  animation: slide-left 0.2s forwards;
}
.slide-left-leave-active {
  animation: slide-right 0.2s forwards;
}
section {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    position: absolute;
    transition: 0.2s;

    @media only screen and (max-width: 1200px) {
        width: 50%;
    }
    @media only screen and (max-width: 768px) {
        width: 80%;
    }

    .return {
        cursor: pointer;
        display: flex;
        gap: 0.25rem;

        figure {
            width: 10px;
            height: 10px;
        }

        span {
            text-transform: uppercase;
            color: var(--secondary-color);
            font-weight: 500;
        }
        
    }
    main {
        background: white;
        width: 100%;
        max-width: 500px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 1px 3px $grey;
        display: flex;
        flex-direction: column;
        position: relative;
        transition: 0.2s;

        @media only screen and (max-width: 1200px) {
            box-shadow: unset;
            background: none;
        }

        header {
            padding: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 1px 3px $grey;
            position: relative;
            transition: 0.2s;

            @media only screen and (max-width: 1200px) {
                display: none;
            }

            h4 {
                font-size: 1rem;
                text-transform: uppercase;
                margin: 0;
                transition: 0.2s;
                color: var(--primary-color);
            }
        }
        form {
            padding: 20px 20px 40px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;

            @media only screen and (max-width: 1200px) {
                gap: 2rem;
                padding: 20px 0;
            }

            .item {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
                width: 100%;

                label {
                    font-weight: 500;
                    color: var(--secondary-color);
                }

                input {
                    border: solid 1px $grey;
                    border-radius: 2px;
                    padding: 10px 20px;
                    transition: 0.2s;

                    &.error {
                        border: solid 1px $red;
                    }

                    &::placeholder {
                        opacity: 0.5;
                    }
                }
            }

            .forgot {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                span {
                    font-size: 0.8rem;
                    user-select: none;
                    cursor: pointer;
                    color: var(--secondary-color);
                    &:hover {
                        text-decoration: underline;
                    }
                }

                
            }

            button {
                border-radius: 50px;
                margin-top: 20px;
                width: 50%;
                text-transform: uppercase;
                font-weight: 500;

                @media only screen and (max-width: 1200px) {
                    margin-top: 0px;
                }
            }
        }
    }
}

.loading {
    background: none;
    height: unset;

    .loader {
        border: 2px solid #f3f3f3;
        border-top: 2px solid var(--primary-color);
        width: 15px;
        height: 15px;
    }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(400px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes slide-right {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(-400px);
  }
}
</style>